body {
  margin: 0;
  font-family: "Bai Jamjuree", serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* ---------------------------------------------
/ General
/ ---------------------------------------------*/

.space-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex {
  display: flex;
  flex-direction: row;
}

span {
  font-size: 22px;
  color: white;
}

/* ---------------------------------------------
/ Hero Section
/ ---------------------------------------------*/

.top-left-name {
  position: relative;
  left: 4vw;
  top: 3vh;
}

.floating-logo {
  cursor: pointer;
  z-index: 10;
  position: fixed;
  bottom: 25vh;
  left: 16.6667%;
  transform: translateX(-50%);
  width: 25vw;
}

/* Use a grid layout with three columns */
.container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; /* Three equal columns (left/middle/right) */
  height: 100vh;
}

/* Middle column text area */
.text-container {
  height: 46vh;          /* Force each "active" column to half of the viewport */
  align-self: center;    /* Vertically center them within the 100vh container */
  padding: 0 1rem;

  color: white;
  text-align: left;

  display: flex;
  flex-direction: column;
  justify-content: space-between; 
}

.image-container {
  height: 60vh;          /* Force each "active" column to half of the viewport */
  align-self: center;    /* Vertically center them within the 100vh container */

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 2rem;
}

/* Image styling so it scales responsively */
.image-container img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover; 
}

/* ---------------------------------------------
/ About section
/ ---------------------------------------------*/

.about {
  min-height: 50vh;
  position: relative;
  width: 100%;
}

.bigger-text {
  font-size: 28px;
}

.container.second-section {
  height: auto;
  margin-top: 2rem;
}

.overflow-middle {
  position: relative;
  width: 100%;       /* 100% normal width + 60% overflow */
  overflow: visible; /* let it overflow horizontally */
  /* Some padding for aesthetics */
  padding: 10rem 1rem 1rem 1rem;
}

.waiting-list-button {
  span {
    font-size: 15px;
  }
  color: white;
  background-color: transparent;
  border: 2px solid white;
  border-radius: 24px;
  padding: 7px;
  width: 160px;
  cursor: pointer;
}

.waiting-list-button:hover {
  span {
    color: black;
  }
  background-color: #fff;
}

/* ---------------------------------------------
/ Waiting List Modal
/ ---------------------------------------------*/

.modal-container {
  position: relative;
  max-width: 400px;
  width: 100%;
  padding: 60px;
}

.styled-input {
  font-family: "Bai Jamjuree", serif;
  background: transparent;
  color: #fff;
  margin-top: 30px;
  margin-bottom: 10px;
  padding: 12px;
}
.styled-input::placeholder {
  font-family: "Bai Jamjuree", serif;
  font-size: 15px;
}

.close-button-svg {
  width: 36px;
  height: 36px;
}

.modal-register-button {
  font-size: 18px;
  padding: 10px;
}

/* ---------------------------------------------
/ Switch to single-column layout on small screens
/ ---------------------------------------------*/

@media (max-width: 768px) {
  .container {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto; /* One row for text, one row for image */
    height: auto;
  }

  .top-left-name {
    left: 10vw;
    top: 3vh;
  }
  
  .floating-logo {
    position: absolute;
    left: auto;
    bottom: auto;
    right: 1vw;
    top: 3vh;
  }

  .overflow-middle {
    width: auto;
    overflow: auto;
    padding: 20rem 0 0 0;
    margin: 0 10vw;
  }

  .text-container, .image-container {
    height: auto !important; /* override the 46vh, 50vh */
  }
  /* Ensure the text container’s margin is reasonable in mobile view */
  .text-container {
    /* height: 28vh !important; */
    margin: 0 10vw;
    margin-top: 10vh;
    margin-bottom: 5vh;
    padding: 0;
  }

  /* The image now appears below the text */
  .image-container {
    margin: 0 10vw;
    padding: 0;
  }

  .about {
    margin-top: 16vh
  }

  .page-not-found {
    display: flex;
    flex-direction: column
  }
}

/* ---------------------------------------------
/ Font resizing
/ ---------------------------------------------*/

@media (max-width: 1600px) {
  span {
    font-size: 20px;
  }
  .bigger-text {
    font-size: 24px;
  }
}

@media (max-width: 1200px) {
  .text-container {
    height: 40vh;
  }
  span {
    font-size: 18px;
  }

  .bigger-text {
    font-size: 22px;
  }
}

@media (max-width: 1050px) {
  .text-container {
    height: 38vh;
  }
  span {
    font-size: 16px;
  }
  .bigger-text {
    font-size: 20px;
  }
}

/* smallest */
@media (max-width: 645px) {
  .top-left-name {
    display: none;
  }

  .floating-logo {
    right: -2vw;
    top: 2vh;
  }

  .about {
    span {
      font-size: 16px;
    }
  }

  .modal-container {
    width: 60vw !important;
    padding: 40px 30px;
  }

  .styled-input {
    padding: 10px;
    margin-top: 20px !important;
    margin-bottom: 10px !important;
  }

  .close-button-svg {
    width: 26px;
    height: 26px;
  }

  .modal-register-button {
    font-size: 16px;
    padding: 8px;
  }
}
